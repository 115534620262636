import axios from "axios";
import { createContext } from "react";
//import ReactGA from "react-ga";
import ReactGA from "react-ga4";
// import { store } from "react-notifications-component";
import { ReactNotifications, Store } from 'react-notifications-component'

export const initGA = (id) => {
    //   if (process.env.NODE_ENV === "production") {
    ReactGA.initialize(id);
    //   }
};

export const RequestFormContext = createContext({

});

export const download = async (url) => {
    try {
        const response = await axios.get(url, { responseType: 'blob' })
        const responseUrl = window.URL
            .createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = responseUrl;
        link.setAttribute('download', url.split('/').pop());
        document.body.appendChild(link);
        link.click();
    }
    catch (e) {
        console.log("this is getting wrong::", e)
    }
    // axios({
    //     url:url,
    //     method:'GET',
    //     responseType: 'blob'
    // })
    // .then((response) => {
    //     const url = window.URL
    //     .createObjectURL(new Blob([response.data]));
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', 'image.jpg');
    //     document.body.appendChild(link);
    //     link.click();
    // })
}


export const mediaLibraryFilters = [
    {
        id: 1,
        title: "topic",
        label: "topic",
        options: [
            {
                label: "Surgical procedure",
                value: "surgical_procedure"
            },
            {
                label: "Patient testimonial",
                value: "patient_testimonial"
            },
            {
                label: "Surgeon testimonial",
                value: "surgeon_testimonial"
            },
            {
                label: "Product information",
                value: "product_information"
            },
            {
                label: "Webinar",
                value: "webinar"
            },
        ]
    },
    {
        id: 2,
        title: "device",
        label: "device",
        options: [
            {
                label: "Perceval platform",
                value: "perceval_platform"
            },
            {
                label: "Memo platform",
                value: "memo_platform"
            },
            // {
            //     label: "Solo Smart",
            //     value: "solo_smart"
            // },
            {
                label: "HAART",
                value: "haart"
            },
            {
                label: "Carbomedics Top Hat",
                value: "carbomedics_top_hat"
            },
            {
                label: "Sovering",
                value: "sovering"
            },
            {
                label: "Sovering Tricuspid Band",
                value: "sovering_tricuspid_band"
            },
        ]
    },
    {
        id: 3,
        title: "approach",
        label: "approach",
        options: [
            {
                label: "Full Sternotomy",
                value: "full_sternotomy"
            },
            {
                label: "Mini Sternotomy",
                value: "mini_sternotomy"
            },
            {
                label: "Trans-Axillary",
                value: "trans_axillary"
            },
            {
                label: "Endoscopic",
                value: "endoscopic"
            },
            {
                label: "Right Mini-Thoracotomy",
                value: "right_mini_thoracotomy"
            },
            // {
            //     label: "Right Mini-Thoracotomy (periareolar)",
            //     value: "right_mini_thoracotomy_periareolar"
            // },
            // {
            //     label: "Right Mini-Thoracotomy (Video Ass)",
            //     value: "right_mini_thoracotomy_video_ass"
            // },
        ]
    },
    {
        id: 4,
        title: "procedure",
        label: "procedure",
        options: [
            {
                label: "Aortic Valve Repair",
                value: "aortic_valve_repair"
            },
            {
                label: "Aortic Valve Replacement",
                value: "aortic_valve_replacement"
            },
            {
                label: "Aortic Valve Replacement + CABG",
                value: "aortic_valve_replacement_plus_cabg"
            },
            {
                label: "Aortic Valve Replacement + Mitral Valve Replacement",
                value: "aortic_valve_replacement_plus_mitral_valve_replacement"
            },
            {
                label: "Mitral Valve Repair",
                value: "mitral_valve_repair"
            },
            {
                label: "Tricuspid Valve Repair",
                value: "tricuspid_valve_repair"
            },
        ]
    },
    {
        id: 5,
        title: "product",
        label: "product",
        options: [
            {
                label: "Aortic",
                value: "aortic"
            },
            {
                label: "Mitral",
                value: "mitral"
            },
            {
                label: "Minimally Invasive Instruments",
                value: "minimally_invasive_instruments"
            },
            {
                label: "Tricuspid",
                value: "tricuspid"
            },
        ]
    },
    {
        id: 6,
        title: "product_feature",
        label: "product feature",
        options: [
            {
                label: "Sutureless",
                value: "sutureless"
            },
            // {
            //     label: "stentless",
            //     value: "stentless"
            // },
            {
                label: "Rechord System",
                value: "rechord_system"
            },
        ]
    },
]

export const mergedOptions = mediaLibraryFilters.reduce((acc, item) => {
    return acc.concat(item.options);
}, []);
export const getAuthUser = () => {
    let token = localStorage.getItem("academy_access_token");
    if (token) {
        return JSON.parse(token).user;
    } else {
        return false;
    }
}

export const getToken = () => {
    let token = localStorage.getItem("academy_access_token");
    if (token) {
        return JSON.parse(token).access_token;
    } else {
        return false;
    }
}

export const removeToken = () => {
    localStorage.removeItem('academy_access_token')
}

export const setToken = (access_token) => {
    localStorage.setItem("academy_access_token", JSON.stringify(access_token));
}

export const success_alert = (message, title) => {
    Store.addNotification({
        title: title ? title : "",
        message: message,
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 7000,
            onScreen: true,
            showIcon: true
        }
    })
}

export const error_alert = (message, title) => {
    Store.addNotification({
        title: title ? title : "",
        message: message,
        type: "danger",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 7000,
            onScreen: true,
            showIcon: true
        }
    })
}

export const warning_alert = (message, title) => {
    Store.addNotification({
        title: title ? title : "",
        message: message,
        type: "warning",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 7000,
            onScreen: true,
            showIcon: true
        }
    })
}

export const removeUserOnboardingData = () => {
    localStorage.removeItem('academy_boarding')
}

export const setUserOnboardingData = (on_boarding) => {
    localStorage.setItem("academy_boarding", on_boarding);
}

