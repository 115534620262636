import React, { useEffect } from 'react';
import HubspotForm from 'react-hubspot-form'

import { useGlobalContext } from '../PopupContext';
import OnboardingForm from '../OnboardingForm/OnboardingForm';

import { FaTimes } from 'react-icons/fa';
import { AiFillCloseCircle } from 'react-icons/ai';

import { BiLoaderAlt } from 'react-icons/bi';

const PopupModal = () => {
  const { isModalOpen, closeModal } = useGlobalContext();

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden"
    }

    return () => document.body.style.overflow = "auto"
  }, [isModalOpen]);

  return (
    <>
      {/* <div className="flex items-center w-screen " style={{zIndex:10001}}></div> */}
      <div className={`${isModalOpen ? 'modal-overlay show-modal' : 'modal-overlay'}`} >
        <div className="lg:w-3/5 bg-gray-light shadow-md rounded-lg px-5 lg:px-8 2xl:px-20 py-20 mx-auto h-full overflow-y-scroll">

          {/* <p
            className="text-3xl text-red-primary mb-4 flex justify-end font-extrabold cursor-pointer "
            onClick={closeModal}>
            <AiFillCloseCircle />
          </p> */}

          {/* <div>
            <HubspotForm
              portalId='43791610'
              formId='1f6f1419-4c3a-4555-b141-0a45b9f33c82'
              onSubmit={() => console.log('Submit!')}
              onReady={(form) => console.log('Form ready!')}
              loading={
                <div className="h-full w-full flex items-center justify-center">
                  <BiLoaderAlt className="text-7xl text-blue-primary animate-spin" />
                </div>
              }
            />
          </div> */}

          <div className="flex justify-between mb-5 pl-5  md:pl-10 lg:pl-8 text-red-primary">
            <p class="text-xl font-bold">
              Please enter your details.
              <br />
              <span class="text-base">
                The fields marked with asterisks are required.
              </span>
            </p>
          </div>

          <OnboardingForm />
        </div>
      </div>
    </>
  );
};

export default PopupModal;
