import React,{useContext} from 'react';
import {BsDownload} from "react-icons/bs";

import { RequestFormContext } from '../../../../utils';


const ProductResources = () => {

    const ctx = useContext(RequestFormContext);

    const clickHandler = ()=>{
        ctx.setRequestFormState(true);
        ctx.setBroucherValue('memo-3d-rechord');
        ctx.setBtnRef("memo3DR_downlaod");
    }
    return (
        <>
            {/* Product Resources container start */}
            <div className="md:p-10">

                 {/* First Section start */}
                <div>
                    <h2 className = "uppercase tracking-wide md:text-xl font-light py-1 pl-5 w-5/12 md:w-3/12 text-left bg-blue-primary text-white inline-block">
                        BROCHURES</h2>
                    <div className = "border border-gray-300 p-5 md:p-10 mt-10 lg:w-5/12 ml-5">
                        <div className = "">
                            <h3 className = " mb-2 text-blue-primary"> MITRAL ANNULOPLASTY RING</h3>
                            <h2 className = "font-bold text-2xl uppercase text-blue-primary">MEMO 3D<sup className = "font-light text-sm">TM</sup> Rechord</h2>
                        </div>
                        <div className = "flex justify-end">
                            {/* //download button */}

                            <a href="https://corcym.s3.eu-central-1.amazonaws.com/brochure/CC-MK-00012+C+Memo3D_Rechord_brochure.pdf"
                            target = "_blank"
                            id="memo3DR_downlaod"
                            className="">
                                {/* <p>PDF</p> <BsDownload /> */}
                            </a>
                             <button className="transition hover:bg-white border-1 border-red-primary
                                hover:text-red-primary text-gray-light text-sm
                                font-light bg-red-primary py-1 px-8 rounded-full flex justify-center
                                items-center space-x-4"
                                onClick = {clickHandler}>
                                    <p>Download</p>
                            </button>
                        </div>
                    </div>
                </div>

                 {/* Second section start */}
            {/* feature container end */}
            </div>







        </>
    )
}

export default ProductResources
