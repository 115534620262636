
// imageUrl: "/images/academy/online_module1.png",
// imageUrl: "/images/academy/online_module1_small.png",

export const coursesArray = [
    {
        id: 1,
        title: "Online Modules",
        description: "",
        description: "This online course provides insights into the transaxillary approach, covering key steps for AVR with transaxillary surgical access through both theoretical and practical details across a series of modules.",
        imageUrl: "/images/academy/online_module1.png",
        progress: 0,
        category: "Perceval",
        isEnrolled: false,
        course_details: [
            {
                id: 1,
                courseId: 1,
                title: "Welcome and introducion",
                hasWatched: false,
                // description: "This is also a sort video",
                thumbnail: "/images/academy/lecture1_video1.png",
                video_link: "https://corcym.s3.eu-central-1.amazonaws.com/academy/online-courses/Corcym_1_Dresden_Masterin_Transaxillary_course-introduction.mp4",
                time_stamps: [],
                attachments: [
                    {
                        name: "Corcym Training Center: Program Course",
                        // description: "Here is the notes of what is taught in this video lecture",
                        link: "https://corcym.s3.eu-central-1.amazonaws.com/academy/uploads/CC-MK-001401_A_CTC_leaflet_HZ_Dresden_In_person.pdf"
                    },
                    {
                        name: "Corcym Virtual Training Center: Program Course",
                        // description: "Here is the notes of what is taught in this video lecture",
                        link: "https://corcym.s3.eu-central-1.amazonaws.com/academy/uploads/CC-MK-001402_A_CTC_leaflet_HZ_Dresden_VIRTUAL.pdf"
                    },
                ],
            },
            {
                id: 2,
                courseId: 1,
                title: "CT scan analysis and surgery planning",
                hasWatched: false,
                // description: "This is also a sort video",
                thumbnail: "/images/academy/lecture1_video2.png",
                video_link: "https://corcym.s3.eu-central-1.amazonaws.com/academy/online-courses/Corcym_2_Dresden_Masterin_Transaxillary_course-CT_scan_protocol.mp4",
                time_stamps: [],
                attachments: [
                    {
                        name: "CORCYM CT Scan reading service",
                        // description: "Here is the notes of what is taught in this video lecture",
                        link: "https://corcym.s3.eu-central-1.amazonaws.com/academy/uploads/CC-MK-001093_C_Brochure_CT_Scan_reading_service.pdf"
                    },
                ],
            },
            {
                id: 3,
                courseId: 1,
                title: "Instruments set up",
                hasWatched: false,
                // description: "This is also a sort video",
                thumbnail: "/images/academy/lecture1_video3.png",
                video_link: "https://corcym.s3.eu-central-1.amazonaws.com/academy/online-courses/Corcym_3_Dresden_Masterin_Transaxillary_course-Instruments.mp4",
                time_stamps: [],
                attachments: [
                    // {
                    //     name: "Safety Info Perceval Plus",
                    //     description: "Here is a small challenge",
                    //     link: "https://corcym.s3.eu-central-1.amazonaws.com/academy/3+Safety+Info+Perceval+Plus.pptx"
                    // },
                ],
            },
            {
                id: 4,
                courseId: 1,
                title: "Patient positioning and anesthesiological access",
                hasWatched: false,
                // description: "This is also a sort video",
                thumbnail: "/images/academy/lecture1_video4.png",
                video_link: "https://corcym.s3.eu-central-1.amazonaws.com/academy/online-courses/Corcym_4_Dresden_Masterin_Transaxillary_course-patient_positioning.mp4",
                time_stamps: [],
                attachments: [
                    // {
                    //     name: "Safety Info Perceval Plus",
                    //     description: "Here is a small challenge",
                    //     link: "https://corcym.s3.eu-central-1.amazonaws.com/academy/4+Safety+Info+Perceval+Plus.pptx"
                    // },
                ],
            },
            {
                id: 5,
                courseId: 1,
                title: "Step-by-Step surgical video",
                hasWatched: false,
                // description: "This is also a sort video",
                thumbnail: "/images/academy/lecture1_video5.png",
                video_link: "https://corcym.s3.eu-central-1.amazonaws.com/academy/online-courses/Corcym_5_Dresden_Mastering_Transaxillary_course-procedural_ideo.mp4",
                time_stamps: [],
                attachments: [
                    // {
                    //     name: "Safety Info Perceval Plus",
                    //     description: "Here is a small challenge",
                    //     link: "https://corcym.s3.eu-central-1.amazonaws.com/academy/5+Safety+Info+Perceval+Plus.pptx"
                    // },
                ],
            }
        ]
    },
]