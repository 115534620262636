import { configureStore } from "@reduxjs/toolkit";

//reducers below
import userReducer from "./slices/userSlice";
import coursesReducer from "./slices/coursesSlice";
//reducers above

export default configureStore({
    reducer: {
        user: userReducer,
        courses: coursesReducer,
    },
    // devTools: process.env.NODE_ENV !== 'production',
})
