import React, { useEffect } from 'react'
//import ReactGA from 'react-ga';
import ReactGA from 'react-ga4';
import { BrowserRouter } from "react-router-dom";

// notifications alerts
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
// import 'animate.css/animate.min.css';
// notifications alerts

//redux imports
import store from "./redux/store";
import { Provider } from 'react-redux';
//redux imports

import BaseRoutes from './routes';
import { getAuthUser } from './utils';
import ScrollToTop from "./routes/Scroll";
import { login } from './redux/slices/userSlice';
import RouteChangeTracker from "./routes/RouteChangeTracker";
import { PopupModalProvider } from './components/PopupContext';
import PopupModal from './components/PopupModal/PopupModal';

const App = () => {
  // state for popup
  // state for popup

  useEffect(() => {
    const cookieAccepted = () => {
      const name = "cookies=";
      const cDecoded = decodeURIComponent(document.cookie);
      const cArr = cDecoded.split('; ');
      let res;
      cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
      })
      return res
    }

    const TRACKING_ID = process.env.REACT_APP_TRACKING_ID_GA4;
    if (cookieAccepted() == 'true') {

      ReactGA.initialize(TRACKING_ID);
    }
    else {
      ReactGA.initialize(TRACKING_ID, {
        GaOptions: {
          anonymizeIp: true
        }
      });
    }
    // this piece of code checks whether a user has previously agreed to cookie policy.
    // if they have, we simply initialize reactGA.
    //if they haven't well, it means that no "cookies" was found in localstorage. reactGA will not be initialized until they accept..... yad rkhin khuda da wasta bhulin na
  }, []);

  useEffect(() => {
    let authUser = getAuthUser();
    if (authUser) {
      store.dispatch(login(authUser));
    }
  }, []);

  return (
    <>
      <Provider store={store}>
        <PopupModalProvider>
          <BrowserRouter>
            <ReactNotifications />
            <RouteChangeTracker />
            <ScrollToTop />
            <BaseRoutes />
            <PopupModal />
          </BrowserRouter>
        </PopupModalProvider>
      </Provider>
    </>
  )
}

export default App;