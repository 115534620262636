import React from 'react'

const MainDescription = () => {
    return (
    <>
            <div className="flex">
                {/* design bar start */}
                <div className="flex-shrink-0 hidden lg:block flex-shrink-0 w-16 bg-repeat-y bg-contain " style={{backgroundImage: "url(/images/pattern.svg)"}}></div>
                {/* design bar end */}

                {/* container of actual content start */}
                <div className="flex-grow bg-white">
                    {/* flex start */}
                    <div className="flex flex-wrap px-8 lg:pr-20 py-12">
                        {/* left side main sub-heading start */}
                        <h3 className="description-heading lg:w-1/5">
                            Designed to standardize mitral repair
                        </h3>
                        {/* left side main sub-heading end */}

                        {/* right side of description points start */}
                            <div className="w-full lg:w-4/5 flex flex-wrap lg:flex-nowrap xl:space-x-6">
                                
                                {/* description start */}
                                <div className="w-full lg:w-2/5  mb-4 lg:mb-0  border-l-8  border-blue-primary px-4">
                                    <p className="description-p">
                                        Memo 3D<sup>TM</sup> ReChord is an advanced repair device designed for 
                                        desirable Patient outcomes<sup>1,2*</sup> while facilitating the surgical procedure
                                    </p>
                                </div>
                                {/* description end */}
                                
                                {/* description start */}
                                <div className="w-full lg:w-2/5 mb-4 lg:mb-0 border-l-8  border-blue-primary px-4">
                                <p className="description-p">
                                    With its innovative chordal guiding system, it turns artificial 
                                    chordae replacement into standard procedure<sup>1</sup>
                                </p>
                                </div>
                                {/* description end */}

                                {/* description start */}
                                <div className="w-full lg:w-2/5 mb-4 lg:mb-0 border-l-8  border-blue-primary px-4">
                                <p className="description-p">
                                    The right balance of rigidity and flexibility to support both degenerative 
                                    and functional mitral repair<sup>3,4,5</sup>
                                </p>
                                </div>
                                {/* description end */}

                            </div>
                        {/* right side of description points end */}
                    </div>
                    {/* flex end */}

                    {/* small code container start */}
                    <div className = "lg:flex justify-between mb-7">
                        <p className="text-xs md:text-sm px-6 text-left feature-p-text w-full lg:w-2/3">
                            <sup>*</sup> Based on CORCYM post-market surveillance, ring-related 
                            mitral regurgitation is expected to occur between 1 and 10 times per 
                            10000 device population.
                        </p>
                        <p className="px-6 mt-5 lg:text-right lg:mt-0 text-xs md:text-base feature-p-text w-full lg:w-1/3">
                            CC-MK-00025 A
                        </p>
                    </div>
                    
                    {/* small code container end */}

                </div>
                {/* container of actual content end */}
            </div>
            
            

            {/* blue bar below start */}
            <div className="h-6 bg-red-primary"></div>
            {/* blue bar below end */}
        </>
    )
}

export default MainDescription
