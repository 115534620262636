import React, { useEffect } from 'react'
const ChangeChoiceCookie = ({setCookie}) => {

    const changeSetting = () => {

        setCookie(true);

        return true;
    }

    return (
        <div style={{zIndex: "10000", width: 'auto', height: 'auto'}}className="space-x-4 w-full py-4 px-4 fixed bottom-0 right-0 text-white flex-wrap lg:flex-nowrap flex justify-end items-center">
            
            <img src="/images/cookie.svg"
            style={{width: "2.5em", height: "2.5em"}}
            className="cursor-pointer flex-shrink-0"
            onClick={changeSetting} title="Change Cookie policy"
            />
            
        </div>
    )
}

export default ChangeChoiceCookie
