import React from 'react'
import {Link} from "react-router-dom";
import {VscLinkExternal} from "react-icons/vsc";

//constants
import {
    FEATURES,
    CLINICAL_EVIDENCE,
    REFERENCES,
    PRODUCT_RESOURCES,
    SAFETY_IFNORMATION,
} from "../../../../constants";

// component
import SelectionDesktop from "./SelectionDesktop";
import SelectionMobile from "./SelectionMobile";

const SelectionList = ({section, setSection, setMobileSection, setMobileOverlay}) => {

    return (
        <>
        {/* DESKTOP Start */}
            <div className="hidden lg:block w-1/4 bg-white">
                <SelectionDesktop section={section} setSection={setSection} title={FEATURES}/>
                <SelectionDesktop section={section} setSection={setSection} title={CLINICAL_EVIDENCE}/>
                <SelectionDesktop section={section} setSection={setSection} title={REFERENCES}/>
                <SelectionDesktop section={section} setSection={setSection} title={PRODUCT_RESOURCES}/>
                <SelectionDesktop section={section} setSection={setSection} title={SAFETY_IFNORMATION}/>
                {/* Section Link Start */}
                <a href="https://www.corcymmanuals.com" target="_blank" className="flex justify-between px-4 xl:px-12 py-8 shadow-md text-lg xl:text-xl 2xl:text-2xl  uppercase cursor-pointer font-normal text-gray-dark">
                    Instructions for use
                    <VscLinkExternal/>
                </a>
                <Link to="/contact-us" className="block px-4 xl:px-12 py-8 shadow-md text-lg xl:text-xl 2xl:text-2xl  uppercase cursor-pointer font-normal text-gray-dark">
                    Contact us
                </Link>
                {/* Section Link End */}
                {/* left selection link end */}
            </div>
        {/* DESKTOP End*/}
        
        {/* MOBILE Start*/}
            <div className="block w-full lg:hidden bg-white">
                <SelectionMobile setMobileOverlay={setMobileOverlay} setMobileSection={setMobileSection} title={FEATURES}/>
                <SelectionMobile setMobileOverlay={setMobileOverlay} setMobileSection={setMobileSection} title={CLINICAL_EVIDENCE}/>
                <SelectionMobile setMobileOverlay={setMobileOverlay} setMobileSection={setMobileSection} title={REFERENCES}/>
                <SelectionMobile setMobileOverlay={setMobileOverlay} setMobileSection={setMobileSection} title={PRODUCT_RESOURCES}/>
                <SelectionMobile setMobileOverlay={setMobileOverlay} setMobileSection={setMobileSection} title={SAFETY_IFNORMATION}/>
                {/* Section Link Start */}
                <a href="https://www.corcymmanuals.com" target="_blank" className="flex justify-between px-4 xl:px-12 py-8 shadow-md text-lg xl:text-2xl uppercase cursor-pointer text-blue-primary font-normal">
                    Instructions for use
                    <VscLinkExternal/>
                </a>
                <Link to="/contact-us" className="block px-4 xl:px-12 py-8 shadow-md text-lg xl:text-2xl uppercase cursor-pointer text-blue-primary font-normal">
                    Contact us
                </Link>
                {/* Section Link End */}
                {/* left selection link end */}
            </div>
        {/* MOBILE Start*/}
        
        </>
    )
}

export default SelectionList;
