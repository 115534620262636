import React from 'react'
const SelectionDesktop = ({title, section, setSection}) => {
    return (
        <div 
            className={`block px-4 xl:px-12 py-8 shadow-md text-lg xl:text-xl 2xl:text-2xl uppercase cursor-pointer ${section === title ? "text-red-primary font-extrabold bg-gray-light" : "text-gray-dark font-normal"}`}
            onClick={() => {setSection(title)}}
        >
                    {title}
        </div>
    )
}

export default SelectionDesktop
