import React,{useEffect} from 'react'
import {FaTimes} from "react-icons/fa";

//constants
import {
    FEATURES,
    CLINICAL_EVIDENCE,
    REFERENCES,
    PRODUCT_RESOURCES,
    SAFETY_IFNORMATION,
    CONTACT_US,
} from "../../../../constants";

//sections import below
import Features from "./Features";
import ClinicalEvidence from "./ClinicalEvidence";
import References from "./References";
import ProductResources from "./ProductResources";
import SafetyInformation from "./SafetyInformation";
//sections import above

const MobileSectionLayout = ({mobileSection,  mobileOverlay, setMobileOverlay}) => {
    
    // useEffect(()=> {
    //     if(mobileOverlay){
    //         document.querySelector("body").style.overflowY = "hidden"
    //     } else {
    //         document.querySelector("body").style.overflowY = "auto"
    //     }

    //     return (() => {
    //         document.querySelector("body").style.overflowY = "auto"
    //     })
    // })


    return (
        <div className={`${mobileOverlay ? 'translate-x-0' : 'translate-x-full'} block lg:hidden w-full z-1000 top-0 bottom-0 right-0 transform transition bg-white fixed py-4 bg-white`}>
            <div className="py-4 flex justify-end w-full">
                <span className="text-3xl text-red-primary cursor-pointer" onClick={() => setMobileOverlay(false)}>
                    <FaTimes />
                </span>
            </div>
            <div className="h-full w-full overflow-y-auto px-4 pb-16">
            {
                mobileSection === FEATURES ?
                <Features/>
                : mobileSection === CLINICAL_EVIDENCE ?
                <ClinicalEvidence/>
                : mobileSection === REFERENCES ?
                <References />
                : mobileSection === PRODUCT_RESOURCES ?
                <ProductResources />
                : mobileSection === SAFETY_IFNORMATION ?
                <SafetyInformation/>
                :
                ""
            }
            </div>
        </div>
    )
}

export default MobileSectionLayout
