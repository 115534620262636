export const links = [
    {
        title: "ABOUT US",
        to: "/about",
        submenu:
            [
                {
                    title: "ABOUT US",
                    to: "/about"
                },
                {
                    title: "VALUES",
                    to: "/about#values"
                },
                {
                    title: "Ethics & Integrity",
                    to: "/ethics"
                },
                {
                    title: "Careers",
                    to: "/careers"
                },
                {
                    title: "Meet the Team",
                    to: "/meet-the-team"
                }
            ]
    },
    {
        title: "DEVICES",
        to: "/devices",
        submenu:
            [
                {
                    title: "DEVICES",
                    to: "/devices"
                },
                {
                    title: "Aortic",
                    to: "/devices/aortic",
                    /*submenu:
                    [
                        {
                            title: "Aortic",
                            to: "/devices/aortic"
                        },
                        {
                            title: "US",
                            to: "/devices/aortic?section=us",
                            submenu:
                            [
                                {
                                    title: "Perceval Plus Relyon System",
                                    to: "/devices/aortic/US/perceval-plus"
                                },
                                {
                                    title: "Perceval",
                                    to: "/devices/aortic/US/perceval"
                                },
    
                                {
                                    title: "Solo Smart",
                                    to: "/devices/aortic/US/solo-smart"
                                },
                                {
                                    title: "Carbomedics",
                                    to: "/devices/aortic/US/carbo-medics"
                                },
                                {
                                    title: "Carbo-Seal Valsalva",
                                    to: "/devices/aortic/US/conduits"
                                }
                            ]
                        },
                        {
                            title: "OUT OF US",
                            to: "/devices/aortic?section=ous",
                            submenu:
                            [
                                {
                                    title: "Perceval Plus",
                                    to: "/devices/aortic/perceval-plus"
                                },
                                {
                                    title: "Perceval",
                                    to: "/devices/aortic/perceval"
                                },
                                {
                                    title: "Solo Smart",
                                    to: "/devices/aortic/solo-smart"
                                },
                                {
                                    title: "Crown PRT",
                                    to: "/devices/aortic/crown-prt"
                                },
                                {
                                    title: "Bicarbon",
                                    to: "/devices/aortic/bi-carbon"
                                },
                                {
                                    title: "Carbomedics",
                                    to: "/devices/aortic/carbo-medics"
                                },
                                {
                                    title: "Carbomedics Carbo-Seal<br />And Carbo-Seal Valsalva",
                                    to: "/devices/aortic/conduits"
                                }
                            ]
                        }
                    ]*/
                },
                {
                    title: "Mitral",
                    to: "/devices/mitral",
                    /*submenu:
                    [
                        {
                            title: "Mitral",
                            to: "/devices/mitral",
                        },
                        {
                            title: "US",
                            to: "/devices/mitral?section=us",
                            submenu:
                            [
                                {
                                    title: "Memo 4D",
                                    to: "/devices/mitral/US/memo4d"
                                },
                                {
                                    title: "Memo 3D",
                                    to: "/devices/mitral/US/memo3d"
                                },
                                {
                                    title: "Annuloflex",
                                    to: "/devices/mitral/US/annuloflex"
                                },
                                {
                                    title: "Annuloflo",
                                    to: "/devices/mitral/US/annuloflo"
                                },
                                {
                                    title: "Carbomedics",
                                    to: "/devices/mitral/US/carbomedics"
                                }
                            ]
                        },
                        {
                            title: "OUT OF US",
                            to: "/devices/mitral?section=ous",
                            submenu:
                            [
                                {
                                    title: "Memo 4D",
                                    to: "/devices/mitral/memo4d"
                                },
                                {
                                    title: "Memo 3D Rechord",
                                    to: "/devices/mitral/memo3d-rechord"
                                },
                                {
                                    title: "Memo 3D",
                                    to: "/devices/mitral/memo3d"
                                },
                                {
                                    title: "Annuloflex",
                                    to: "/devices/mitral/annuloflex"
                                },
                                {
                                    title: "Annuloflo",
                                    to: "/devices/mitral/annuloflo"
                                },
                                {
                                    title: "Sovering",
                                    to: "/devices/mitral/sovering"
                                },
                                {
                                    title: "Carbomedics",
                                    to: "/devices/mitral/carbomedics-m"
                                },
                                {
                                    title: "Bicarbon",
                                    to: "/devices/mitral/bicarbon-m"
                                },
                                {
                                    title: "Pericarbon More",
                                    to: "/devices/mitral/pericarbon-m"
                                }
                            ]
                        }
                    ]*/
                },
                {
                    title: "MINIMALLY INVASIVE INSTRUMENTS",
                    to: "/devices/miami-instruments",
                    /*submenu:
                    [
                        {
                            title: "MINIMALLY INVASIVE INSTRUMENTS",
                            to: "/devices/miami-instruments"
                        },
                        {
                            title: "US",
                            to: "/devices/miami-instruments?section=us",
                            submenu:
                            [
                                {
                                    title: "Detach Detachable<br />Head Aortic Cross Clamp",
                                    to: "/devices/miami/US/detach"
                                },
                                {
                                    title: "Suture Belt Circumferential<br />Suture Organizer",
                                    to: "/devices/miami/US/suture-belt"
                                },
                                {
                                    title: "Joseph Lamelas Knot Pusher",
                                    to: "/devices/miami/US/knot-pusher"
                                },
                                {
                                    title: "Tie Intracorporeal Knot<br />Placement Assist Device",
                                    to: "/devices/miami/US/tie"
                                },
                                {
                                    title: "Joseph Lamelas Intercostal<br />Retractor System",
                                    to: "/devices/miami/US/intercostal-retractor-system"
                                },
    
                                {
                                    title: "Joseph Lamelas Atrial Lift System",
                                    to: "/devices/miami/US/atrial-life-system"
                                },
                                {
                                    title: "Cuff Aortic Root Exposure Device",
                                    to: "/devices/miami/US/cuff"
                                },
                                {
                                    title: "Collar Papillary Muscle<br />Exposure Device",
                                    to: "/devices/miami/US/collar"
                                }
                            ]
                        }
                    ]*/
                }
            ]
    },
    {
        title: "PATIENTS & CAREGIVERS",
        to: "/patients-and-caregivers-other-countries",
        /*submenu:
        [
            {
                title: "PATIENTS AND CAREGIVERS",
                to: "/patients-and-caregivers-other-countries"
            },
            {
                title: "Aortic Stenosis",
                to: "/patients-and-caregivers-other-countries#aortic-stenosis"
            },
            {
                title: "Heart Stories",
                to: "/patients-and-caregivers-other-countries#heart-stories"
            },
            {
                title: "References",
                to: "/patients-and-caregivers-other-countries#references"
            },
            {
                title: "Resources",
                to: "/patients-and-caregivers-other-countries#resources"
            }
        ]*/
    },
    {
        title: "HEALTHCARE PROFESSIONALS",
        to: "/education",
        submenu:
            [
                {
                    title: "HEALTHCARE PROFESSIONALS",
                    to: "/education"
                },
                /*{
                    title: "CORCYM ACADEMY",
                    to: "https://corcym-academy.com/"
                },*/
                {
                    title: "CORCYM ACADEMY",
                    to: "/academy"
                },
                {
                    title: "ERAS@Corcym",
                    to: "/eras"
                },
                {
                    title: "CT Scan",
                    to: "/ct-scan"
                },
                /*{
                    title: "MEDIA LIBRARY",
                    to: "/media-library",
                    submenu:
                    [
                        {
                            title: "",
                            to: ""
                        },
                        {
                            title: "Aortic Solutions",
                            to: "/media-library/aortic-solutions"
                        },
                        {
                            title: "Mitral Solutions",
                            to: "/media-library/mitral-solutions"
                        }
                    ]
                }*/
            ]
    },
    // {
    //     title: "Ethics & Integrity",
    //     to: "/ethics"
    // },
    // {
    //     title: "Team",
    //     to: "/team"
    // },
    /*{
        title: "NEWS",
        to: "/news"
    },*/
    {
        title: "CONTACT US",
        to: "/contact-us",
        // submenu:
        // [
        //     {
        //         title: "Contact us",
        //         to: "/contact-us"
        //     },
        //     {
        //         title: "Information Request",
        //         to: "/contact-us#information-request"
        //     },
        //     {
        //         title: "Customer Service",
        //         to: "/customer-service"
        //     },
        //     {
        //         title: "Investigator Initiated Research",
        //         to: "/research"
        //     },
        //     {
        //         title: "Donations and Grants",
        //         to: "/donations-and-grants"
        //     },
        //     {
        //         title: "Product Safety, Patient Card and Device Tracking",
        //         to: "/product-safety"
        //     }
        // ]
    },
    {
        title: "PARTNER WITH US",
        to: "/partner-with-us",
    }
]
