import React from 'react'

const References  = () => {
    return (
        <>

{/* References container start */}
<div className="flex flex-wrap lg:flex-nowrap items-center mb-20">
                {/* References container start */}
                <div className="w-full">
                    <ol className="list-decimal pl-6 space-y-4">
                        <li className="text-sm 2xl:text-base text-blue-primary font-bold">
                            <p className = "font-normal feature-p-text pl-3 ">Glauber et al. </p>
                            <p className = "feature-p-text  pl-3 ">Minimally invasive mitral valve
                            repair using a semi-rigid annuloplasty ring with a new chordal
                            sizing system: the Memo3D ReChord.</p>
                            <p className = "font-normal italic feature-p-text pl-3 ">
                                Ann Cardiothorac Surg 2015;4(3):298-300.</p>
                        </li>
                        <li className="text-sm 2xl:text-base text-blue-primary font-bold">
                            <p className = "font-normal feature-p-text pl-3 ">Wan et al. </p>
                            <p className = "feature-p-text  pl-3 ">Mitral valve repair using a semirigid ring: Patient
                                selection and early outcomes. </p>
                            <p className = "font-normal italic feature-p-text pl-3 ">
                            Asian Cardiovascular {"&"} Thoracic Annals
                            0(0) 1–6 The Author(s) 2016.</p>
                        </li>
                        <li className="text-sm 2xl:text-base text-blue-primary font-bold">
                            <p className = "font-normal feature-p-text pl-3 ">  Nasso et al. </p>
                            <p className = "feature-p-text  pl-3 ">
                                Three-Year Results of Repaired Barlow Mitral Valves
                                via Right Minithoracotomy versus Median Sternotomy
                                in a Randomized Trial. </p>
                            <p className = "font-normal italic feature-p-text pl-3 ">
                                Cardiology 2014;128:97–105.</p>
                        </li>
                        <li className="text-sm 2xl:text-base text-blue-primary font-bold">
                            <p className = "font-normal feature-p-text pl-3 "> Fattouch et al. </p>
                            <p className = "feature-p-text  pl-3 ">
                                A Comparison of 2 Mitral Annuloplasty Rings for Severe
                                Ischemic Mitral Regurgitation: Clinical and
                                Echocardiographic Outcomes. </p>
                            <p className = "font-normal italic feature-p-text pl-3 ">
                            SeminThoracic Surg 28:261–268 I 2016.</p>
                        </li>
                        <li className="text-sm 2xl:text-base text-blue-primary font-bold">
                            <p className = "font-normal feature-p-text pl-3 "> Bruno et al. </p>
                            <p className = "feature-p-text  pl-3 ">
                                Early Clinical Experience and Echocardiographic
                                Results With a New Semirigid Mitral Annuloplasty
                                Ring: The Sorin Memo 3D. </p>
                            <p className = "font-normal italic feature-p-text pl-3 ">
                                Ann Thorac Surg 2009;88:1492– 8.</p>
                        </li>
                        <li className="text-sm 2xl:text-base text-blue-primary font-bold">
                            <p className = "font-normal feature-p-text pl-3 "> Nishi et al. </p>
                            <p className = "feature-p-text  pl-3 ">
                                Annular dynamics of Memo 3D annuloplasty ring
                                evaluated by 3D transesophageal echocardiography.</p>
                            <p className = "font-normal italic feature-p-text pl-3 ">
                                General Thoracic and Cardiovascular Surgery, 2018 Apr;66(4):214-219</p>
                        </li>
                        <li className="text-sm 2xl:text-base text-blue-primary font-bold">
                            <p className = "font-normal feature-p-text pl-3 "> Ryomoto et al. </p>
                            <p className = "feature-p-text  pl-3 ">
                            Physiological mitral annular dynamics preserved after
                            ring annuloplasty in mid-term period. </p>
                            <p className = "font-normal italic feature-p-text pl-3 ">
                            General Thoracic and Cardiovasc Surgery (2017) 65:627–632</p>
                        </li>
                        <li className="text-sm 2xl:text-base text-blue-primary font-bold">
                            <p className = "font-normal feature-p-text pl-3 "> Santarpino et al. </p>
                            <p className = "feature-p-text  pl-3 ">
                                First-in-man implantation of a Sorin Memo 3D ring:
                                Mitral annular flexibility is still preserved at 5
                                years of follow-up! </p>
                            <p className = "font-normal italic feature-p-text pl-3 ">
                            International Journal of Cardiology 159 (2012) e23–e24.</p>
                        </li>
                        <p className = "feature-p-text font-bold">
                            Technical claims supported by CORCYM data on file
                        </p>

                    </ol>

                </div>
                {/* feature text container end */}
            </div>
            {/* feature container end */}

        </>
    )
}

export default References
