import React, {useState} from 'react'
// router link tag
import {Link} from "react-router-dom";
//icon
import {FaAngleRight} from 'react-icons/fa';
//local sections below
import Footer from "../../../components/Footer"

// local sections below
import Hero from "./Hero";
import MainDescription from "./MainDescription";
import SectionContainer from "./Sections";
// local sections above


const Memo3DR = () => {
    return (
        <div className="h-full flex flex-col navbar-padding">
            {/* page flex for extending all the way down to the footer */}
            <div className="flex-grow">

                {/* page heading */}
                <div className = "">
                    <h1 className="font-medium text-base sm:text-lg md:text-xl tracking-wider uppercase
                    py-2 lg:py-4 pl-6 md:pl-10 xl:pl-20 w-1/2 sm:w-1/3 lg:w-1/4 inline-block bg-blue-primary text-white ">
                        MEMO 3D<sup className="font-light">TM</sup> Rechord
                    </h1>
                </div>

                {/* page sub title */}
                <div className="bg-red-primary"></div>

                {/* breadcrumb links heading start */}
                <div className="overflow-x-auto py-4 w-full pl-6 md:pl-10 xl:pl-20 bg-gray-light">
                    <h6 className="uppercase font-medium text-sm md:text-base 2xl:text-lg text-blue-primary flex items-center space-x-1 md:space-x-4">
                        <Link to="/">
                            Home
                        </Link>
                        <span><FaAngleRight/></span>
                        <Link to="/devices">
                            Devices
                        </Link>
                        <span><FaAngleRight/></span>
                        <Link to="/devices/mitral">
                            Mitral
                        </Link>
                        <span><FaAngleRight/></span>
                        <Link to="/devices/mitral/memo3d-rechord">
                            Memo 3D<sup>TM</sup> Rechord
                        </Link>

                    </h6>
                </div>
                {/* breadcrumb links heading end */}

                {/* main hero section with bg start */}
                <Hero />
                {/* main hero section with bg end */}

                {/* description below banner start */}
                <MainDescription />
                {/* description below banner end */}

                {/* Section for changing sections start */}
                <SectionContainer />
                {/* Section for changing sections start */}

            </div>
            {/* page flex for extending all the way down to the footer */}

            <Footer color={"bg-white"}/>
        </div>
    )
}

export default Memo3DR
