import React from 'react'

const Features = () => {
    return (
        <>

            { /* feature container start */ }
            <div className = "flex flex-wrap lg:flex-nowrap items-center mb-5" >
            { /* feature heading container start */ }
                <div className = "order-2 lg:order-1 w-full lg:w-4/6" >
                    <h4 className = "feature-heading-a1" >
                        An easy and fast implant procedure<sup>1,2</sup>
                    </h4>
                </div>
                { /* feature heading container end */ }
            </div> { /* feature container end */ }
            {/* feature container start */}
            <div className="flex flex-wrap lg:flex-nowrap items-start mb-10">
                {/* feature text container start */}
                <div className="w-full lg:w-4/6">
                    <p className = "feature-p-text">Solo Smart biological aortic valve features a “temporary stent” that:</p>
                    <ul className="list-disc pl-6 space-y-4 pt-6">
                        <li className="text-sm 2xl:text-base text-red-primary">
                            <p className = "feature-p-text">Provides proper visibility of implantation site during
                                valve positioning and suturing</p>
                        </li>
                        <li className="2xl:text-base text-red-primary">
                            <p className = "feature-p-text">
                                Gives support and facilitates Solo valve implantation</p>
                        </li>
                        <li className="2xl:text-base text-red-primary">
                            <p className = "feature-p-text">
                                Requires less time to complete valve suturing</p>
                        </li>
                        <li className="2xl:text-base text-red-primary">
                            <p className = "feature-p-text">
                                Is designed to reduce the learning curve in first-time users.</p>
                        </li>
                    </ul>
                </div>
                {/* feature text container end */}

                {/* feature image container start */}
                <div className="mt-4 md:mt-0 mb-4 w-full lg:w-2/6 pl-4 justify-end">
                    <img src="/images/solo_smart/An-easy.png" className="mx-auto w-1/5 lg:w-2/5"/>
                </div>
                {/* feature image container end */}
            </div>
            {/* feature container end */}


            {/* feature container start */}
            <div className="flex flex-wrap lg:flex-nowrap items-center mb-10">
                {/* feature text container start */}
                <div className="order-2 lg:order-1 w-full lg:pr-40">
                    <h4 className="feature-heading-a1">
                        Meeting the needs of an active lifestyle
                    </h4>
                    <p className="2xl:text-base mb-8 feature-p-text">
                        Solo Smart has no rigid support and is placed supra-annularly, meaning it does not restrict blood flow, offering excellent hemodynamic
                        results, even during exercise.<sup>*</sup></p>
                    <p className = "feature-p-text">Hemodynamic performance of Solo Smart under exercise is characterized by:<sup>1,3</sup></p>
                    <ul className="list-disc pl-6 space-y-4 pt-6">
                        <li className="2xl:text-base text-red-primary">
                            <p className = "feature-p-text">Low gradients under stress</p>
                        </li>
                        <li className="2xl:text-base text-red-primary">
                            <p className = "feature-p-text">
                            Modest mean gradients increase from rest to stress</p>
                        </li>
                        <li className="2xl:text-base text-red-primary">
                            <p className = "feature-p-text">
                            EOA and EOAi increase during exercise</p>
                        </li>

                    </ul>
                </div>
                {/* feature text container end */}
            </div>
            {/* feature container end */}


            {/* feature container start */}
            <div className="flex flex-wrap lg:flex-nowrap items-center mb-16">


                {/* feature image container start */}
                <div className="order-1 lg:order-2 w-full lg:w-2/4 justify-end mb-4">
                    <img src="/images/solo_smart/Gradients.png" className="mx-auto w-full md:w-4/5" />
                </div>
                {/* feature image container end */}

                {/* feature image container start */}
                <div className="order-1 lg:order-2 w-full lg:w-2/4 justify-end mb-4">
                    <img src="/images/solo_smart/EOA.png" className="mx-auto w-full md:w-4/5" />
                </div>
                {/* feature image container end */}
            </div>
            {/* feature container end */}


            {/* feature container start */}
            <div>
                {/* feature text container start */}
                <div className="w-full ">
                    <p className="text-sm 2xl:text-base mb-6 feature-p-text xl:w-4/5">
                    Early clinical and hemodynamic results after aortic valve replacement with the Freedom Solo bioprosthesis
                    (experience of Italian multicenter study).<sup>1</sup>
                    </p>

                    <p className=" text-xs mb-6 feature-p-text italic leading-relaxed pr-20">
                    <sub className="align-top text-xs">*</sub>Comparison made considering hemodynamic data on Solo Smart
                    (Repossini et al., Eur J Cardiothorac Surg (2012) 41(5): 1104-1110) and data on native valves
                    (Hanke et al., European Journal of Cardio-Thoracic Surgery (2013) 1–7).
                    </p>
                </div>
                {/* feature text container end */}


            </div>
            {/* feature container end */}


        </>
    )
}

export default Features
