import React, {useState} from 'react'
import {Link} from "react-router-dom"
import {FiChevronDown, FiChevronUp} from "react-icons/fi";
import SecondSubmenu from './SecondSubmenu';

const Submenu = (props) => {

    const [showSecondSubmenu, setSecondShowSubmenu] = useState(null);
    const [secondDropdown, setSecondDropdown] = useState(false);

    //second submenu
    const showSecondSubmenuHandler = (menuVoice) => {

        if(typeof menuVoice == "undefined" || menuVoice.length <= 0)
        {
            if(showSecondSubmenu || showSecondSubmenu == 0)
            {
                document.getElementById("hamburgerSecondSubmenuMenu" + showSecondSubmenu).classList.remove("text-red-primary");
                document.getElementById("hamburgerSecondSubmenuMenu" + showSecondSubmenu).classList.add("text-white");
            }
            
            closeSecondSubmenuHandler();

            return false;
        }

        if(typeof props.submenus[menuVoice] == "undefined" || typeof props.submenus[menuVoice].submenu == "undefined")
        {
            if(showSecondSubmenu || showSecondSubmenu == 0)
            {
                document.getElementById("hamburgerSecondSubmenuMenu" + showSecondSubmenu).classList.remove("text-red-primary");
                document.getElementById("hamburgerSecondSubmenuMenu" + showSecondSubmenu).classList.add("text-white");
            }

            closeSecondSubmenuHandler();
            
            return false;
        }

        if(secondDropdown && showSecondSubmenu == menuVoice)
        {
            document.getElementById("hamburgerSecondSubmenuMenu" + showSecondSubmenu).classList.remove("text-red-primary")
            document.getElementById("hamburgerSecondSubmenuMenu" + showSecondSubmenu).classList.add("text-white"); 
            
            setSecondDropdown(false);
        }
        else
        {
            if(showSecondSubmenu || showSecondSubmenu == 0)
            {
                document.getElementById("hamburgerSecondSubmenuMenu" + showSecondSubmenu).classList.remove("text-red-primary")
                document.getElementById("hamburgerSecondSubmenuMenu" + showSecondSubmenu).classList.add("text-white"); 
            }
            
            document.getElementById("hamburgerSecondSubmenuMenu" + menuVoice).classList.add("text-red-primary");
            setSecondDropdown(true);
        }

        setSecondShowSubmenu(menuVoice);

        return true;
    }

    const closeSecondSubmenuHandler = () => {
        setSecondDropdown(false);
        setSecondShowSubmenu(null);

        return true;
    }


    return (
            <div className='overflow-y-hidden bg-blue-primary pt-3 w-full'>
                {props.submenus.map((submenu, key) => (
                    key > 0 ?
                        submenu.submenu ?
                            <ul className="dropdown flex flex-wrap lg:flex-no-wrap">
                                <div className="w-full mb-2 text-white pl-2" key={key} id={`hamburgerSecondSubmenuMenu` + key}>
                                    <div className='w-4/5 flex items-center justify-start left' style={{float: 'left'}}>
                                        <a href={submenu.to} className='uppercase text-base text-left font-medium block focus:outline-none d-flex items-center h-full' aria-haspopup="menu">{submenu.title}</a> 
                                    </div>
                                    <div className='w-1/5 flex items-center justify-end left' style={{float: 'left'}}>
                                        {
                                            showSecondSubmenu ==  key && secondDropdown ?
                                                <FiChevronUp className="mx-2 cursor-pointer d-flex items-center h-full" onClick={() => showSecondSubmenuHandler(key)} style={{float: 'right', marginTop: submenu.title.toUpperCase() == "MINIMALLY INVASIVE INSTRUMENTS" ? '12px' : '0px'}}/>
                                            :
                                                <FiChevronDown className="mx-2 cursor-pointer d-flex items-center h-full" onClick={() => showSecondSubmenuHandler(key)} style={{float: 'right', marginTop: submenu.title.toUpperCase() == "MINIMALLY INVASIVE INSTRUMENTS" ? '12px' : '0px'}}/>
                                        }
                                    </div>
                                    {
                                        showSecondSubmenu ==  key && secondDropdown ?
                                            <div className='w-full'>
                                                <SecondSubmenu 
                                                    submenus={props.submenus[showSecondSubmenu].submenu}
                                                />
                                            </div>
                                            :
                                            ''
                                    }
                                </div>
                                <br />
                            </ul>
                        :
                            <ul className="dropdown">
                                <li className="aaa relative transition uppercase text-gray-light font-medium h-full py-1 mb-2 pl-2" key={key} activeClassName="active_link" style={{listStyleType: "none"}}  id={`menuVoice${key}`}>

                                        <a href={submenu.to} className='h-full d-flex items-center hover:text-red-primary' aria-haspopup="menu">{submenu.title}</a>

                                </li>
                            </ul>
                    :
                    ''
                ))}
            </div>
    );
  };
  
  export default Submenu;