import axios from "axios";
import { getToken } from "../utils";
import axiosCancel from 'axios-cancel';
import {BASE_URL} from "../constants"
import {logout} from "../redux/slices/userSlice";
import {error_alert} from "../utils";
import store from "../redux/store";

//any global headers go here.
let headers = {
    Accept: "application/json",
};

// create axios instance
export const Axios = axios.create({
    baseURL: BASE_URL + "/",
    headers: headers,
});

// check if we have a token and then append it to the axios instance
Axios.interceptors.request.use(
    config => {
        const token = getToken();
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        Promise.reject(error);
    }
)

Axios.interceptors.response.use((response) => {
    return response
  }, function (error) {
    // const originalRequest = error.config;
    if (error.response && error.response.status === 401){
        store.dispatch(logout());
        localStorage.removeItem('academy_access_token')
        error_alert(JSON.stringify(error.response.data.detail));
        return new Promise(() => { });
    } else if (error.response && error.response.status === 403){
        store.dispatch(logout());
        localStorage.removeItem('academy_access_token')
        error_alert("Your session expired! Please log in again to continue");
        return new Promise(() => { });
    }else {
        return Promise.reject(error);
    }

  }); 

// Using this because we might have to cancel requests
axiosCancel(Axios, {
    debug: false // default
})