import React, { useState , useEffect } from 'react';
import SearchTags from '../../SearchData.json';

import ResultCard from '../ResultCard/ResultCard';

import Axios from 'axios';
import { BASE_URL } from '../../constants';

function SearchResult({searchTerm}) {

    const [newsdata, setNewsData] = useState([]);

    const newsurl = BASE_URL + 'news/news';

    //get news
    useEffect(() => {
        Axios.get(newsurl)
          .then(res => {
              setNewsData(res.data.payload);
          }).catch(err => {
            console.log("Error search news: ", err);
        })
    }, []);


    //pages
    let pageFilteredResult = SearchTags.filter((val) => {
        if(typeof searchTerm == "undefined" || searchTerm == null || searchTerm.length <= 0 || searchTerm == ","
            || typeof val.tag == "undefined" || typeof val.title == "undefined" || typeof val.link == "undefined")
        {
                  
            return "";
        }
        else if(val.tag.toLowerCase().includes(searchTerm.toLowerCase())){
                
                return val;
        }
    });

    //news
    const newsFilteredResult = newsdata.filter((val) => {
        if(typeof searchTerm == "undefined" || searchTerm == null || searchTerm.length <= 0  || searchTerm == ","
            || typeof val.news_type == "undefined" || val.news_type != "news" || typeof val.id == "undefined"
            || typeof val.title == "undefined" || typeof val.description == "undefined" || typeof val.body == "undefined")
        {
                  
            return "";
        }

        let news = val.title + " " + val.description + " " + val.body;

        //remove html element
        news = stripHtml(news);

        if(news.toLowerCase().includes(searchTerm.toLowerCase()))
        {
            let newsVal = {
                    "title": val.title,
                    "link": "/news-detail/" + val.id
            };

            //news will be show after the pages
            pageFilteredResult.push(newsVal);
            return newsVal;
        }
    })


    //remove html element
    function stripHtml(html)
    {
        if(typeof html == "undefined" || html == null || html.length <= 0 )
        {
            return "";
        }

        let tmp = document.createElement("div");
        tmp.innerHTML = html;

        return tmp.textContent || tmp.innerText || "";
    }

    return (
        <>
            {/* <h1>{searchTerm}</h1> */}

            {/* {
                filteredResult.length == 0 ?
                   <div>No results Found</div>
                :
                filteredResult.map((val,key) => {

                    
                    return <div key= {key}><h1>{val.title} </h1> <a href = {val.link}>{val.link}</a></div>
                    
        
                   })

            } */}
            
            { pageFilteredResult.map((val,key) => 
                {
                        if(val)
                        {
                            return <ResultCard key={key} title={val.title} link={val.link} />
                        }
                        else
                        {
                            return <p>No results found</p>
                        }          
                })
            }
        </>
    )
}

export default SearchResult
