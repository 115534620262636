import React from 'react';
import { Link } from 'react-router-dom';
import {WEBSITE_BASE_URL} from '../../constants';
 
const ResultCard = (props) => {

    return (
        <>
        <a href = {props.link} className ="hover:underline">
            <div className = "p-5">
                <h1>{props.title} </h1> 
                {/* <a href = {props.link}>{props.link}</a> */}
            </div>
        </a>
        </>
    )
    
}

export default ResultCard
