import React, {useState} from 'react'

//constants
import {
    FEATURES,
    CLINICAL_EVIDENCE,
    REFERENCES,
    PRODUCT_RESOURCES,
    SAFETY_IFNORMATION,
    CONTACT_US,
} from "../../../../constants";

//selection list for changing which secion is selected
import SelectionList from "../SelectionList";

//sections import below
import Features from "./Features";
import ClinicalEvidence from "./ClinicalEvidence";
import References from "./References";
import ProductResources from "./ProductResources";
import SafetyInformation from "./SafetyInformation";
//sections import above

// mobile sidelayout below
import MobileSectionLayout from "./MobileSectionLayout";
// mobile sidelayout above

const SectionContainer = () => {
    // by default, set state to Features
    const [section, setSection] = useState(FEATURES);

    //mobile section and overlay status
    const [mobileSection, setMobileSection] = useState(false);
    const [mobileOverlay, setMobileOverlay] = useState(false);
    
    return (
        <>
            <div className="flex">
                {/* left selection container start */}
                <SelectionList section={section} setSection={setSection} setMobileSection={setMobileSection} setMobileOverlay={setMobileOverlay}/>
                {/* left selection container end */}

                {/* right side container where selected content gets populated start */}
                <div className="hidden lg:block w-3/4 bg-gray-light px-8 py-8">
                    {
                    section === FEATURES ?
                    <Features/>
                    : section === CLINICAL_EVIDENCE ?
                    <ClinicalEvidence/>
                    : section === REFERENCES ?
                    <References />
                    : section === PRODUCT_RESOURCES ?
                    <ProductResources />
                    : section === SAFETY_IFNORMATION ?
                    <SafetyInformation/>
                    :
                    ""
                    }
                </div>
                {/* right side container where selected content gets populated end */}
            </div>

            <MobileSectionLayout mobileSection={mobileSection} mobileOverlay={mobileOverlay} setMobileOverlay={setMobileOverlay}/>
        </>
    )
}

export default SectionContainer
