import { createSlice } from "@reduxjs/toolkit";
import { coursesArray } from "../../pages/Academy/OnlineCoursesData";

const initialState = {
    all_courses: coursesArray
};

const coursesSlice = createSlice({
    initialState,
    name: "courses",
    reducers: {
        get_initial_courses: (state, action) => initialState,
        update_all_courses: (state, action) => ({
            ...state,
            all_courses: action.payload
        }),
    }
})

export default coursesSlice.reducer;

export const {
    get_initial_courses,
    update_all_courses
} = coursesSlice.actions;
