import React from 'react'

import { MdPlayArrow } from "react-icons/md";

const ClinicalEvidence = () => {
    return (
        <>
            {/* ClinicalEvidence container start */}

            { /* feature heading container start */ }
            <div className = "order-2 lg:order-1 w-full lg:w-4/6" >
                <h4 className = "feature-heading-m1" >
                    Performance
                </h4>
            </div>
            { /* feature heading container end */ }

        {/*********************************(1)***********************************************/}

          <div className="flex flex-wrap lg:flex-nowrap items-center my-10 lg:pr-20">

                {/* feature text container start */}
                <div className="order-2 lg:order-1 w-full">
                    <h3 className="feature-heading-m2">
                        TRULY 3D MOTION
                    </h3>
                    <p className = "feature-p-text mb-5 text-justify">
                        The truly 3D motion of the ring during the cardiac cycle
                        preserves the non-planar saddle shape geometry of the annulus.
                        Recent clinical data have demonstrated that Memo 3D is able to
                        accommodate the physiological saddle shape of the mitral annulus
                        throughout the cardiac cycle upon implantation.<sup>6,7</sup>
                    </p>
                </div>
                {/* feature text container end */}
            </div>
            {/* feature container end */}
        {/*********************************(2)***********************************************/}

            <h3 className="feature-heading-m2">
                SYSTOLIC REMODELING AND DIASTOLIC DYNAMICS CONCEPT
            </h3>
          <div className="flex flex-wrap lg:flex-nowrap my-5 lg:pr-10">

            {/* feature text container start */}
            <div className="order-2 lg:order-1 w-full lg:w-1/2">
                <p className = "feature-p-text mb-5 md:pr-5">
                    Truly three-dimensional motion of the mitral annulus with a
                    anterior/posterior to lateral/lateral relationship to maximize
                    blood flow <sup>6,7</sup> even more than five years after implantation.<sup>10</sup>
                </p>
            </div>
            {/* feature text container end */}

            {/* feature image container start */}
            <div className="mb-4 order-1 lg:order-2 w-full lg:w-1/2 pl-4 ">
                <div>
                    <img src="/images/memo4d/Systolic.png" alt = "Systolic" className="mx-auto w-full "/>
                </div>
                <div className = "flex py-5 justify-center pl-10">
                    <p className = "feature-p-text w-1/2 text-sm">Systolic remodeling optimized coaptation and reduced stress<sup>6</sup></p>
                    <p className = "feature-p-text w-1/2 text-sm md:pl-1 lg:pl-10">Diastolic dynamics optimized hemodynamics<sup>7</sup></p>
                </div>

            </div>

            {/* feature image container end */}

            </div>
            {/* feature container end */}


        </>
    )
}

export default ClinicalEvidence
