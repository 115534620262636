import React, { useEffect } from 'react';
import { useState } from 'react';
import {FiSearch} from "react-icons/fi";
import {FiX} from "react-icons/fi";
import SearchResult from '../SearchResult/SearchResult';

const Index = (props) => {

    const [searchTerm, setSearchTerm] = useState("");
    const closeHandler = () => {
        props.setSearchOverlay(false);  
    }

    const changeHandler = (e) => {
        setSearchTerm(e.target.value);
    }

    const onSubmitForm = (event) => {
        event.preventDefault();

        return false;
    }    
   
    return (
        <>
            <div style = {{zIndex:"103", opacity:"0.96"}} className = "bg-white fixed overflow-y-hidden h-screen w-screen top-0 left-0 bottom-0">
                {/* <h1 className = "text-black">This is a search page</h1> */}
                <form className = "flex w-full lg:w-1/2 py-4 search-form" onSubmit={onSubmitForm}>
                    <label className = "inline" htmlFor = "search">
                        
                        <div className=" px-10 pt-2">
                            <span className="text-4xl font-extrabold text-gray-text cursor-pointer font-bold" >
                                <FiSearch  />
                            </span>
                        </div>
                    
                    </label>
                    <input 
                    className = "w-full h-10 p-5 outline-none text-3xl border-b-4 border-gray-600" 
                    type = "text" 
                    id = "search" 
                    name = "search" 
                    autoComplete='off'
                    placeholder = "Search..."
                    onChange = {changeHandler}
                    autoFocus
                    />
                    <div className=" px-10 pt-2 flex-shrink-0">
                        <span className="text-4xl font-extrabold  text-gray-text cursor-pointer" >
                            <FiX onClick = {closeHandler}  />
                        </span>
                    </div>
                </form>

                <div className="search-result w-full lg:w-2/5 mx-20 lg:mx-10">
                    {/* <p>Enter your text to begin searching</p> */}
                    <SearchResult searchTerm={searchTerm}  />
                </div>
                
            </div>
        </>
    )
}

export default Index







