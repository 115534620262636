import React from 'react';
import {BsDownload} from 'react-icons/bs';

const SafetyInformation  = () => {
    return (
        <>
            {/* feature container start */}
            <div className=" mb-24 p-5">
                {/* first section start */}

                <div className = "flex flex-wrap w-full justify-between border border-gray-300 p-5">
                    <h2 className = "font-bold text-red-primary text-sm md:text-lg">IMPORTANT SAFETY INFORMATION DOCUMENT - EU</h2>
                    {/* //download button */}

                    <a href="https://corcym.s3.eu-central-1.amazonaws.com/SE-0180+B+-+SOLO+SMART+-+EXTENDED.pdf" target = "_blank" className="transition hover:bg-white border-1 border-blue-primary
                            hover:text-blue-primary text-gray-light text-sm
                            font-light bg-blue-primary py-1 px-8 rounded-full flex justify-center
                            items-center space-x-4 mt-5 md:mt-0">
                                <p>PDF</p> <BsDownload />
                    </a>
                </div>
                <div className = "flex flex-wrap w-full justify-between border border-gray-300 p-5 mt-10">
                    <h2 className = "font-bold text-red-primary text-sm md:text-lg">IMPORTANT SAFETY INFORMATION DOCUMENT - USA {"&"} CANADA</h2>
                    {/* //download button */}

                    <a href="https://corcym.s3.eu-central-1.amazonaws.com/media/safety+information/IM-02412+A_SOLO+SMART+safety+info+-+US+-+EXTENDED+-+CORCYM.pdf"className="transition hover:bg-white border-1 border-blue-primary
                            hover:text-blue-primary text-gray-light text-sm
                            font-light bg-blue-primary py-1 px-8 rounded-full flex justify-center
                            items-center space-x-4 mt-5 md:mt-0">
                                <p>PDF</p> <BsDownload />
                    </a>
                </div>

                {/* second section start */}

                <div className = "md:flex mt-20 justify-between feature-p-text">
                    <div className = "border-l-8 border-red-primary pl-3 md:w-1/3">
                        <p className = "font-bold">Manufactured by:</p>
                    </div>
                    <div className = "pl-3 md:pl-2 mt-3 md:mt-0">
                        <h5>Corcym S.r.l.</h5>
                        <p className = "font-light text-gray-600">Via Crescentino sn <br />
                        13040 Saluggia (VC) Italy <br />
                        Теl: +39 0161 1640001</p>
                        <p className="font-light text-xs text-gray-400">Corcym S.r.l. previously Sorin Group Italia S.r.l.</p>
                    </div>
                    <div className = "pl-3 md:pl-2 mt-3 md:mt-0">
                        <h5>Corcym Canada Corp.</h5>
                        <p className = "font-light text-gray-600">5005 North Fraser Way Burnaby,<br />
                            BC V5J 5M1 Canada <br />
                            Tel: +604 412-5650</p>
                            <p className="font-light text-xs text-gray-400">Corcym Canada Corp. previously LivaNova Canada Corp.</p>
                    </div>

                    <div className = "border-l-8 border-red-primary pl-3 mt-5 md:mt-0">
                        <div className = "flex items-baseline">
                            <img className= "h-8 w-10 inline-block" src = "/images/C-E-logo-1.png" />
                            <p className = "inline-block ml-2"> <sub>0123</sub></p>
                        </div>

                    </div>
                </div>



            </div>










        </>
    )
}

export default SafetyInformation
