import React from 'react'

const References  = () => {
    return (
        <>

{/* References container start */}
<div className="flex flex-wrap lg:flex-nowrap items-center mb-20">
                {/* References container start */}
                <div className="w-full">
                    <ol className="list-decimal pl-6 space-y-4">
                        <li className="text-sm 2xl:text-base text-red-primary font-bold">
                            <p className = "font-normal feature-p-text pl-3 ">Repossini et al. </p>
                            <p className = "feature-p-text  pl-3 ">
                                Early clinical and hemodynamic results after aortic valve replacement with the Freedom SOLO bioprosthesis
                                (experience of Italian multicenter study)</p>
                            <p className = "font-normal italic feature-p-text pl-3 ">Eur J Cardiothorac Surg (2012) 41(5): 1104-1110.</p>
                        </li>
                        <li className="text-sm 2xl:text-base text-red-primary font-bold">
                            <p className = "font-normal feature-p-text pl-3 ">
                            Wollersheim et al. </p>
                            <p className = "feature-p-text  pl-3 ">
                            Aortic Valve Replacement With the Stentless Freedom SOLO Bioprosthesis: A Systematic Review</p>
                            <p className = "font-normal italic feature-p-text pl-3 ">
                            Ann Thorac Surg. 2015 Oct;100(4):1496-504.</p>
                        </li>
                        <li className="text-sm 2xl:text-base text-red-primary font-bold">
                            <p className = "font-normal feature-p-text pl-3  ">
                            Pisarik et al. </p>
                            <p className = "feature-p-text  pl-3 ">
                            Clinical experience with the Freedom Solo stentless aortic valve in 277 consecutive Patients</p>
                            <p className = "font-normal italic feature-p-text pl-3 ">
                            Ann Thorac Surg 2014;98:1301–7.</p>
                        </li>
                        <li className="text-sm 2xl:text-base text-red-primary font-bold">
                            <p className = "font-normal feature-p-text pl-3 ">Beholz et al. </p>
                            <p className = "feature-p-text  pl-3 ">
                            The Freedom SOLO Valve for Aortic Valve Replacement: Clinical and Hemodynamic Results from a Prospective Multicenter Trial</p>
                            <p className = "font-normal italic feature-p-text pl-3 ">
                            The Journal of Heart Valve Disease 2010;19:115-123</p>
                        </li>
                        <p className = "feature-p-text font-bold">
                            Technical claims supported by CORCYM data on file. 
                        </p>
                            
                    </ol>
                    
                </div>
                {/* feature text container end */}
            </div>
            {/* feature container end */}

        </>
    )
}

export default References 
