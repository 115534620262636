import React from 'react'
import {FaPlus} from "react-icons/fa";

const SelectionMobile = ({title, setMobileOverlay, setMobileSection}) => {
    return (
        <div 
            className={`flex justify-between px-4 xl:px-12 py-8 shadow-md text-lg xl:text-2xl uppercase cursor-pointer text-red-primary font-normal`}
            onClick={() => {
                setMobileOverlay(true);
                setMobileSection(title);
            }}
        >
            <>
            {title}
            <FaPlus/>
            </>
        </div>
    )
}

export default SelectionMobile
