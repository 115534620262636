import React, {useState} from 'react'
import {Link} from "react-router-dom"
import {FiChevronDown, FiChevronUp} from "react-icons/fi";
import ThirdSubmenu from './ThirdSubmenu';


const SecondSubmenu = (props) => {

    const [showThirdSubmenu, setThirdShowSubmenu] = useState(null);
    const [thirdDropdown, setThirdDropdown] = useState(false);

    //third submenu
    const showThirdSubmenuHandler = (menuVoice) => {

        if(typeof menuVoice == "undefined" || menuVoice.length <= 0)
        {
            if(showThirdSubmenu || showThirdSubmenu == 0)
            {
                document.getElementById("hamburgerThirdSubmenuMenu" + showThirdSubmenu).classList.remove("text-red-primary");
                document.getElementById("hamburgerThirdSubmenuMenu" + showThirdSubmenu).classList.add("text-white");
            }
            
            closeThirdSubmenuHandler();

            return false;
        }

        if(typeof props.submenus[menuVoice] == "undefined" || typeof props.submenus[menuVoice].submenu == "undefined")
        {
            if(showThirdSubmenu || showThirdSubmenu == 0)
            {
                document.getElementById("hamburgerThirdSubmenuMenu" + showThirdSubmenu).classList.remove("text-red-primary");
                document.getElementById("hamburgerThirdSubmenuMenu" + showThirdSubmenu).classList.add("text-white");
            }

            closeThirdSubmenuHandler();
            
            return false;
        }

        if(thirdDropdown && showThirdSubmenu == menuVoice)
        {
            document.getElementById("hamburgerThirdSubmenuMenu" + showThirdSubmenu).classList.remove("text-red-primary")
            document.getElementById("hamburgerThirdSubmenuMenu" + showThirdSubmenu).classList.add("text-white"); 
            
            setThirdDropdown(false);
        }
        else
        {
            if(showThirdSubmenu || showThirdSubmenu == 0)
            {
                document.getElementById("hamburgerThirdSubmenuMenu" + showThirdSubmenu).classList.remove("text-red-primary")
                document.getElementById("hamburgerThirdSubmenuMenu" + showThirdSubmenu).classList.add("text-white"); 
            }
            
            document.getElementById("hamburgerThirdSubmenuMenu" + menuVoice).classList.add("text-red-primary");
            setThirdDropdown(true);
        }
        console.log(props.submenus[menuVoice].submenu);
        setThirdShowSubmenu(menuVoice);

        return true;
    }

    const closeThirdSubmenuHandler = () => {
        setThirdDropdown(false);
        setThirdShowSubmenu(null);

        return true;
    }


    return (
            <div className='overflow-y-hidden bg-blue-primary pt-3 w-full'>
                {props.submenus.map((submenu, key) => (
                    key > 0 ?
                        submenu.submenu ?
                            <ul className="dropdown flex flex-wrap lg:flex-no-wrap">
                                <div className="w-full mb-2 text-white pl-4" key={key} id={`hamburgerThirdSubmenuMenu` + key}>
                                    <div className='w-4/5 flex items-center justify-start left' style={{float: 'left'}}>
                                        <a href={submenu.to} className='uppercase text-base text-left font-medium block focus:outline-none items-center h-full'>{submenu.title}</a>
                                    </div>
                                    <div className='w-1/5 flex items-center justify-end left' style={{float: 'left'}}>
                                        {
                                            showThirdSubmenu ==  key && thirdDropdown ?
                                                <FiChevronUp className="mx-2 cursor-pointer items-center h-full" onClick={() => showThirdSubmenuHandler(key)} style={{float: 'right'}}/>
                                            :
                                                <FiChevronDown className="mx-2 cursor-pointer items-center h-full" onClick={() => showThirdSubmenuHandler(key)} style={{float: 'right'}}/>
                                        }
                                    </div>
                                    {
                                        showThirdSubmenu ==  key && thirdDropdown ?
                                            <div className='w-full'>
                                                <ThirdSubmenu 
                                                    submenus={props.submenus[showThirdSubmenu].submenu}
                                                />
                                            </div>
                                            :
                                            ''
                                    }
                                </div>
                                <br />
                            </ul>
                        :
                            <ul className="dropdown">
                                <li className="relative transition uppercase text-gray-light font-medium h-full py-1 mb-2 pl-4" key={key} activeClassName="active_link" style={{listStyleType: "none"}}>
                                    <div id={`menuVoice${key}`}>
                                        <a href={submenu.to} className='h-full d-flex items-center hover:text-red-primary' aria-haspopup="menu">{submenu.title}</a>
                                    </div>
                                </li>
                                <br />
                            </ul>
                    :
                    ''
                ))}
            </div>
    );
  };
  
  export default SecondSubmenu;