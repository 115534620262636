import React from 'react'

const Hero = () => {
    return (
        // heading start
        <div className="flex items-end shadow-lg device-hero-section px-6 md:px-12 py-10 md:py-2 justify-between flex flex-wrap md:flex-nowrap bg-red-primary bg-no-repeat bg-right" style={{backgroundPositionX: "102%",backgroundSize: "auto 100%", backgroundImage: "url(/images/solo_smart/solosmart.png)"}}> 
            <div className="heading-container">
                <h2 className="product-banner-heading">
                    <span>Solo smart</span>
                    <span className="align-top text-xs sm:text-sm xl:text-lg">TM</span>
                </h2>
                <p className="product-banner-subheading">STENTLESS VALVE</p>
            </div>
            <img src="/images/products/solo_smart.png" className="order-1 md:order-2 max-w-product-hero mx-auto w-3/4 md:w-4/12 block lg:hidden"/>
        </div>
        //  heading end
    )
}

export default Hero
