import React, { useEffect } from 'react'
import {initGA} from "../../utils"
const Cookie = ({setCookie}) => {

    //if user see banner mustn't have no cookies
    useEffect(() => {
        deleteAllCookies();

        cookieGoogleAnalytics(true);
    }, []);


    const handleChange = (status) => {
        if(typeof status == "undefined" || status == null || status.length <= 0
            || (status.toLowerCase() != "accept" && status.toLowerCase() != "declined" && status.toLowerCase() != "close"))
        {
            return false;
        }

        setCookie(false);

        if(status.toLowerCase() == "accept")
        {
            cookieGoogleAnalytics(false);
        }
        else if(status.toLowerCase() == "declined")
        {
            //delete all cookies if user didn't accept cookies
            deleteAllCookies();

            cookieGoogleAnalytics(true);
        }
        else if(status.toLowerCase() == "close")
        {
            cookieGoogleAnalytics(true);
        }

        let date = new Date(); //now
        date.setTime(date.getTime() + (180*24*60*60*1000)); //180 days - 6 months

        //set technical cookie
        document.cookie = "cookies=" + status.toLowerCase() + "; expires= " + date.toUTCString() + "; path=/";
        
        return true;
    }


    //set google analytics cookie
    const cookieGoogleAnalytics = (anonymised) => 
    {
        if(typeof anonymised == "undefined" || anonymised == null || anonymised.length <= 0)
        {
            anonymised = true;
        }

        if(anonymised)
        {
            // this piece of code below enables google analytics once the user presses the accept button on policy
            if (process.env.REACT_APP_TRACKING_ID_GA4) 
            {
                initGA(process.env.REACT_APP_TRACKING_ID_GA4, {
                    GaOptions: {
                        anonymizeIp: true
                    }
                });
            }
        }
        else
        {
            // this piece of code below enables google analytics once the user presses the accept button on policy
            if (process.env.REACT_APP_TRACKING_ID_GA4) 
            {
                initGA(process.env.REACT_APP_TRACKING_ID_GA4);
            }
        }

        return true;
    }


    //remove all cookies
    const deleteAllCookies = () => 
    {
        let cookies = document.cookie.split(";");
    
        for (let i = 0; i < cookies.length; i++) 
        {
            let cookie = cookies[i];
            let eqPos = cookie.indexOf("=");
            let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }

    return (
        <div style={{zIndex: "10000"}}className="space-x-4 w-full py-4 px-6 fixed bottom-0 bg-black text-white flex-wrap lg:flex-nowrap flex justify-center items-center">
            <p className="text-xs 2xl:text-sm w-full lg:w-auto mb-4 text-center lg:text-left lg:mb-0">
                This site uses technical cookies necessary for its operation and analytical cookies, including third-party anonymised cookies, to carry out aggregate statistical analysis.  By closing the banner you consent to the processing of your personal data through the categories of cookies mentioned.
                <br /><br />
                For more information about the cookies in operation on the site you can consult our <a href="/cookies" className="underline hover:text-blue-primary" title="Consult Cookie Policy" target="_blank">Cookie Policy</a>.
            </p>
            <p
            className="cursor-pointer text-sm bg-green-primary py-1 px-3 rounded-md flex-shrink-0"
            onClick={() => handleChange("close")} title="Close Cookie policy banner"
            >Close</p>
            {/*<p
            className="cursor-pointer text-sm bg-green-primary py-1 px-3 rounded-md flex-shrink-0"
            onClick={() => handleChange("accept")} title="Accept Cookie policy"
            >Accept</p>
            <p
            className="cursor-pointer text-sm bg-red-primary py-1 px-3 rounded-md flex-shrink-0"
            onClick={() => handleChange("declined")} title="Declined Cookie policy"
            >Declined</p>*/}
            <a href="/cookies" className="text-sm bg-blue-primary py-1 px-3 rounded-md flex-shrink-0" title="Consult Cookie Policy" target="_blank">Cookie Policy</a>
        </div>
    )
}

export default Cookie
