import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import NormalRoutes from "./NormalRoutes";
import AuthenticatedRoutes from './AuthenticatedRoutes';

const BaseRoutes = () => {
    const user = useSelector(state => state.user.id);

    return (
        user ?
            <AuthenticatedRoutes />
            :
            <NormalRoutes />
    )
}

export default BaseRoutes