import React,{useContext} from 'react';
import {BsDownload} from "react-icons/bs";

import { RequestFormContext } from '../../../../utils';

const ProductResources = () => {

    const ctx = useContext(RequestFormContext);

    const clickHandler = ()=>{
        ctx.setRequestFormState(true);
        ctx.setBroucherValue('solo-smart');
        ctx.setBtnRef('soloSmart_download');
    }
    return (
        <>
            {/* Product Resources container start */}
            <div className="md:p-10">

                 {/* First Section start */}
                <div>
                    <h2 className = "uppercase tracking-wide md:text-xl font-light py-1 pl-5 w-5/12 md:w-3/12 text-left bg-red-primary text-white inline-block">
                        BROCHURES</h2>
                    <div className = "border border-gray-300 p-5 md:p-10 mt-10 lg:w-5/12 ml-5">
                        <div className = "">
                            <h3 className = "text-red-primary">AORTIC PERICARDIAL HEART VALVE</h3>
                            <h2 className = "font-bold text-2xl uppercase text-red-primary mt-2">SOLO SMART<sup className = "font-light text-xs align-top">TM</sup></h2>
                        </div>
                        <div className = "flex justify-end">
                            {/* //download button */}

                            <a id="soloSmart_download" href="https://corcym.s3.eu-central-1.amazonaws.com/brochure/CC-MK-00015+B+Solo_Smart_brochure.pdf" target = "_blank" className="">
                                {/* <p className = "text-white">PDF</p> <BsDownload /> */}
                            </a>
                            <button className="transition hover:bg-white border-1 border-blue-primary
                                hover:text-blue-primary text-gray-light text-sm
                                font-light bg-blue-primary py-1 px-8 rounded-full flex justify-center
                                items-center space-x-4"
                                onClick = {clickHandler}
                                >
                                    <p>Download</p>
                            </button>
                        </div>  
                    </div>  
                </div>

                 {/* Second section start */}

            {/* feature container end */}
            </div>
          
           
            
            
            


        </>
    )
}

export default ProductResources
