import React, {useState} from 'react'
import {Link} from "react-router-dom"
import {FiChevronDown, FiChevronUp} from "react-icons/fi";


const ThirdSubmenu = (props) => {

    const [showFourthSubmenu, setFourthShowSubmenu] = useState(null);
    const [fourthDropdown, setFourthDropdown] = useState(false);

    //fourth submenu
    const showFourthSubmenuHandler = (menuVoice) => {

        if(typeof menuVoice == "undefined" || menuVoice.length <= 0)
        {
            if(showFourthSubmenu || showFourthSubmenu == 0)
            {
                document.getElementById("hamburgerFourthSubmenuMenu" + showFourthSubmenu).classList.remove("text-red-primary");
                document.getElementById("hamburgerFourthSubmenuMenu" + showFourthSubmenu).classList.add("text-white");
            }
            
            closeFourthSubmenuHandler();

            return false;
        }

        if(typeof props.submenus[menuVoice] == "undefined" || typeof props.submenus[menuVoice].submenu == "undefined")
        {
            if(showFourthSubmenu || showFourthSubmenu == 0)
            {
                document.getElementById("hamburgerFourthSubmenuMenu" + showFourthSubmenu).classList.remove("text-red-primary");
                document.getElementById("hamburgerFourthSubmenuMenu" + showFourthSubmenu).classList.add("text-white");
            }

            closeFourthSubmenuHandler();
            
            return false;
        }

        if(fourthDropdown && showFourthSubmenu == menuVoice)
        {
            document.getElementById("hamburgerFourthSubmenuMenu" + showFourthSubmenu).classList.remove("text-red-primary")
            document.getElementById("hamburgerFourthSubmenuMenu" + showFourthSubmenu).classList.add("text-white"); 
            
            setFourthShowSubmenu(false);
        }
        else
        {
            if(showFourthSubmenu || showFourthSubmenu == 0)
            {
                document.getElementById("hamburgerFourthSubmenuMenu" + showFourthSubmenu).classList.remove("text-red-primary")
                document.getElementById("hamburgerFourthSubmenuMenu" + showFourthSubmenu).classList.add("text-white"); 
            }
            
            document.getElementById("hamburgerFourthSubmenuMenu" + menuVoice).classList.add("text-red-primary");
            setFourthShowSubmenu(true);
        }

        setFourthShowSubmenu(menuVoice);

        return true;
    }

    const closeFourthSubmenuHandler = () => {
        setFourthDropdown(false);
        setFourthShowSubmenu(null);

        return true;
    }


    return (
            <div className='overflow-y-hidden bg-blue-primary mt-3 w-full' style={{paddingRight: "17px"}}>
                {props.submenus.map((submenu, key) => (
                    submenu.submenu ?
                        <ul className="dropdown flex flex-wrap lg:flex-no-wrap">
                            <div className="w-full mb-2 text-white pl-4" key={key} id={`hamburgerFourthSubmenuMenu` + key}>
                                <div className='w-4/5 flex items-center justify-start left' style={{float: 'left'}}>
                                    <a href={submenu.to} className='uppercase text-base text-left font-medium block focus:outline-none d-flex items-center h-full'>{submenu.title}</a>
                                </div>
                                <div className='w-1/5 flex items-center justify-end left' style={{float: 'left'}}>
                                    {
                                        showFourthSubmenu ==  key && fourthDropdown ?
                                            <FiChevronUp className="mx-2 cursor-pointer items-center h-full" onClick={() => showFourthSubmenuHandler(key)} style={{float: 'right'}}/>
                                        :
                                            <FiChevronDown className="mx-2 cursor-pointer items-center h-full" onClick={() => showFourthSubmenuHandler(key)} style={{float: 'right'}}/>
                                    }
                                </div>
                            </div>
                            <br />
                        </ul>
                    :
                        <ul className="dropdown">
                            <li className="relative transition uppercase text-gray-light font-medium h-full py-1 ml-4" key={key} activeClassName="active_link">
                                <div id={`menuVoice${key}`}>
                                    <a href={submenu.to} className='h-full d-flex items-center hover:text-red-primary' aria-haspopup="menu" dangerouslySetInnerHTML={{ __html: submenu.title }}></a>
                                </div>
                            </li>
                        </ul>
                ))}
            </div>
    );
  };
  
  export default ThirdSubmenu;