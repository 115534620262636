import React from 'react'

const MainDescription = () => {
    return (
        <>
            <div className="flex">
                {/* design bar start */}
                <div className="flex-shrink-0 hidden lg:block flex-shrink-0 w-16 bg-repeat-y bg-contain " style={{backgroundImage: "url(/images/pattern.svg)"}}></div>
                {/* design bar end */}

                {/* container of actual content start */}
                <div className="flex-grow bg-white">
                    {/* flex start */}
                    <div className="flex flex-wrap px-8 py-12">
                        {/* left side main sub-heading start */}
                        <h3 className="description-heading lg:w-1/3 lg:pr-20 ">
                            Solo Smart<sup>TM</sup> provides all the
                            benefits of a native-like valve<sup>*</sup>
                            with the ease of a stented biological
                            aortic valve implantation
                        </h3>
                        {/* left side main sub-heading end */}

                        {/* right side of description points start */}
                            <div className="w-full lg:w-2/3 flex flex-wrap lg:flex-nowrap xl:space-x-6">
                                
                                {/* description start */}
                                <div className="w-full lg:w-2/5  mb-4 lg:mb-0  border-l-8  border-red-primary px-4">
                                    <p className="description-p">
                                        Designed for both Surgeons
                                        and Patients to provide
                                        excellent hemodynamics<sup>*</sup>
                                    </p>
                                </div>
                                {/* description end */}
                                
                                {/* description start */}
                                <div className="w-full lg:w-2/5 mb-4 lg:mb-0 border-l-8  border-red-primary px-4">
                                    <p className="description-p ">
                                        Designed for an easy and fast
                                        procedure thanks
                                        to a “temporary stent”
                                        that improves implantability
                                    </p>
                                </div>
                                {/* description end */}

                            </div>
                        {/* right side of description points end */}
                    </div>
                    {/* flex end */}

                    {/* small code container start */}
                    <div className = "lg:flex justify-between mb-7">
                        <p className="text-xs md:text-sm px-6 text-left feature-p-text w-full lg:w-2/3">
                            <sup>*</sup>Comparison made considering hemodynamic data on Solo Smart (Repossini et al., Eur J Cardiothorac Surg (2012) 41(5):
                            1104-1110) and data on native valves (Hanke et al., European Journal of Cardio-Thoracic Surgery (2013) 1–7).
                        </p>
                        <p className="px-6 mt-5 lg:text-right lg:mt-0 text-xs md:text-base feature-p-text w-full lg:w-1/3">
                            CC-MK-00026 A
                        </p>
                    </div>
                    
                    {/* small code container end */}

                </div>
                {/* container of actual content end */}
            </div>
            
            

            {/* blue bar below start */}
            <div className="h-6 bg-blue-primary"></div>
            {/* blue bar below end */}
        </>
    )
}

export default MainDescription
