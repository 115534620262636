import React, { useState, useContext } from 'react';

const PopupModalContext = React.createContext();

const PopupModalProvider = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <PopupModalContext.Provider
            value={{
                isModalOpen,
                openModal,
                closeModal,
            }}
        >
            {children}
        </PopupModalContext.Provider>
    );
};

export const useGlobalContext = () => {
    return useContext(PopupModalContext);
};

export { PopupModalContext, PopupModalProvider };
