import React from 'react'

const Features = () => {
    return (
        <>
            {/* feature container start */}
            { /* feature heading container start */ } 
            <div className = "order-2 lg:order-1 w-full lg:w-4/6 mb-4" >
                <h4 className = "feature-heading-m1" >
                    Innovation
                </h4> 
            </div> 
            { /* feature heading container end */ }

    {/****************************************(1)****************************************************/}
            
            <div className="flex flex-wrap lg:flex-nowrap items-center mb-5">
                
                {/* feature text container start */}
                <div className="w-full lg:w-1/2">
                    <h3 className="feature-heading-m2">
                        FAST AND SIMPLIFIED PROCEDURES <br/> FOR 
                        DESIRABLE PATIENT OUTCOMES<sup>* 1,2</sup>
                    </h3>
                    <p className = "feature-p-text mb-5">
                        Memo 3D ReChord incorporates a series of loops in the posterior 
                        region that act as temporary reference points when sizing the 
                        chords length. The innovative chordal guiding system promotes 
                        standardized chordae replacement, while reducing 
                        procedural time.<sup>1,2</sup>
                    </p>
                </div>
                {/* feature text container end */}

                {/* feature image container start */}
                <div className="mt-4 md:mt-0 mb-4 w-full lg:w-1/2 pl-4 justify-end">
                    <img src="/images/memo3d_r/Procedures.png" className="mx-auto w-full md:w-4/5"/>
                </div>
                {/* feature image container end */}

               
            </div>
            <p className="text-sm text-left italic feature-p-text">
                <sup>*</sup> Based on CORCYM post-market surveillance, 
                ring-related mitral regurgitation is expected to occur between
                    1 and 10 times per 10000 device population.
            </p>
            {/* feature container end */}

    {/*************************************(2)************************************************/}    
            
            { /* feature heading container start */ } 
            <div className = "w-full lg:w-4/6 my-10" >
                <h4 className = "feature-heading-m1" >
                    Design
                </h4> 
            </div> 
            { /* feature heading container end */ }
        {/*****************************************************************/}  

            <div className="flex flex-wrap lg:flex-nowrap items-center mb-20">
                
                {/* feature text container start */}
                <div className="w-full lg:w-1/2">
                    <h3 className="feature-heading-m2">
                        UNIQUE SUPER-ELASTIC ALLOY CORE<sup>5</sup>
                    </h3>
                    <p className = "feature-p-text mb-5">
                        The exclusive alloy core cell design is a laser-cut one-piece 
                        structure that enables annular dynamics mimicking those of a physiological 
                        annulus. The same precision laser-cut technology is also used to create 
                        CORCYM’s innovative Perceval sutureless aortic prosthesis.
                    </p>

                </div>
                {/* feature text container end */}

                {/* feature image container start */}
                <div className="mt-4 md:mt-0 mb-4 w-full lg:w-1/2 pl-4">
                    <img src="/images/memo3d_r/Design.png" alt = "Design" className="mx-auto w-full md:w-4/5"/>
                </div>
                {/* feature image container end */}
            </div>
            {/* feature container end */}

    {/************************************(3)*************************************************/}         
             
            <div className="flex flex-wrap lg:flex-nowrap items-center mb-20">
                
                {/* feature text container start */}
                <div className="w-full lg:w-1/2">
                    <h3 className="feature-heading-m2">
                        SHAPE MEMORY
                    </h3>
                    <p className = "feature-p-text mb-5">
                        Memo 3D’s superelastic alloy core “remembers” its prefixed 
                        shape, meaning it returns to its original form even after being 
                        flexed back and forth. This shape memory is important as it 
                        provides recovery of the systolic profile and restores the 
                        natural systolic diameter ratio.<sup>6,7</sup>
                    </p>

                </div>
                {/* feature text container end */}

                {/* feature image container start */}
                <div className="mt-4 md:mt-0 mb-4 w-full lg:w-1/2 pl-4 justify-end">
                    <img src="/images/memo3d_r/Memory.png" alt = "Design3"className="mx-auto w-full md:w-4/5"/>
                </div>
                {/* feature image container end */}
            </div>
            {/* feature container end */}

    {/*************************************(4)************************************************/}
                        
    <div className="flex flex-wrap lg:flex-nowrap items-center mb-20">
                
                {/* feature text container start */}
                <div className="w-full lg:w-1/2">
                    <h3 className="feature-heading-m2">
                        STABILITY AND SUPPORT
                    </h3>
                    <p className = "feature-p-text mb-5">
                        The Memo 3D semi-rigid annuloplasty ring has been engineered 
                        to give the stability needed to support the annulus while 
                        ensuring flexibility of movement.<sup>3,4,5</sup>
                    </p>

                </div>
                {/* feature text container end */}

                {/* feature image container start */}
                <div className="mt-4 md:mt-0 mb-4 w-full lg:w-1/2 pl-4 justify-end">
                    <img src="/images/memo3d_r/support.png" alt = "Design4" className="mx-auto w-full md:w-4/5"/>
                </div>
                {/* feature image container end */}
            </div>
            {/* feature container end */}

    {/************************************(5)*************************************************/}
                     
    <div className="flex flex-wrap lg:flex-nowrap items-center mb-10">
                
                {/* feature text container start */}
                <div className="w-full lg:w-1/2">
                    <h3 className="feature-heading-m2">
                        THREE LAYER STRUCTURE
                    </h3>
                    <p className = "feature-p-text mb-5">
                        Ease of implant with good visibility, placement and attachment 
                        to ensure proper annular fit. The oval silicone sheath makes 
                        it easy to penetrate the ring with a needle and suture 
                        it in place.<sup>1</sup>
                    </p>
                </div>
                {/* feature text container end */}

                {/* feature image container start */}
                <div className="mt-4 md:mt-0 mb-4 w-full lg:w-1/2 pl-4 justify-end">
                    <img src="/images/memo3d_r/structure.png" alt = "Design5" className="mx-auto w-full md:w-4/5"/>
                </div>
                {/* feature image container end */}
            </div>
            {/* feature container end */}


        </>
    )
}

export default Features
