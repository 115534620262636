import React from 'react'

const ClinicalEvidence = () => {
    return (
        <>
             {/* feature container start */}
             <div className="flex flex-wrap lg:flex-nowrap items-center mb-10">
                {/* feature text container start */}
                <div className="order-2 lg:order-1 w-full lg:w-1/1">
                    <h4 className="feature-heading-a1">
                    Hemodynamic Performance
                    </h4>
                    <p className = "feature-p-text">Solo Smart provides excellent
                    hemodynamic performances for all valve sizes, even the smallest.<sup>*</sup></p>
                </div>
                {/* feature text container end */}
            </div>
            {/* feature container end */}


            {/* feature container start */}
            <div className="flex flex-wrap lg:flex-nowrap items-center mb-16">

                {/* feature image container start */}
                <div className="order-1 lg:order-2 w-full lg:w-2/4 flex-col justify-end mb-10 lg:md-4">
                    <img src="/images/solo_smart/A.png" className="mx-auto w-full md:w-3/5 lg:w-4/5" />
                    <p className = "px-20 mt-5 feature-p-text ">Early clinical and hemodynamic results after aortic valve
replacement with the Freedom Solo bioprosthesis
(experience of Italian multicenter study)<sup>1</sup></p>
                </div>
                {/* feature image container end */}

                {/* feature image container start */}
                <div className="order-1 lg:order-2 w-full lg:w-2/4 flex-col justify-end mb-4">
                    <img src="/images/solo_smart/B.png" className="mx-auto w-full md:w-3/5 lg:w-4/5" />
                    <p className = " px-20 mt-5 feature-p-text ">The Feedom Solo Valve for Aortic Valve Replacement:
Clinical and Hemodynamic Results from a Prospective
Multicenter Trial<sup>4</sup></p>
                </div>
                {/* feature image container end */}
            </div>
            {/* feature container end */}

            <p className = "feature-p-text">Solo Smart provides hemodynamic performances,
                EOA and EOAi close to those of healthy native-valves, ensuring excellent
                results and low risk of mismatch.*</p>
            {/* feature container start */}

            <div className="flex flex-wrap lg:flex-nowrap items-center mt-5 ">

                {/* feature image container start */}
                <div className="order-1 lg:order-2 w-full lg:w-2/4 justify-end mb-4">
                    <img src="/images/solo_smart/C.png" className="mx-auto w-full md:w-3/5 lg:w-4/5" />
                </div>
                {/* feature image container end */}

                {/* feature image container start */}
                <div className="order-1 lg:order-2 w-full lg:w-2/4 justify-end mb-4">
                    <img src="/images/solo_smart/D.png" className="mx-auto w-full md:w-3/5 lg:w-4/5" />
                </div>
                {/* feature image container end */}
            </div>
            {/* feature container end */}

            <p className=" text-xs mb-6 feature-p-text italic font-bold leading-relaxed pr-20">
                <sub className="align-top text-sm"></sub>“The EOA indices were about 1.0
                cm<sup>2</sup>/m<sup>2</sup> for all valve sizes after 12 months, which suggested almost normal
                hemodynamics at the valvular level, without any need for annular enlargement procedures”<sup> 4</sup>
            </p>

            {/* feature container start */}

            <div className="flex flex-wrap lg:flex-nowrap items-center mt-5 mb-16">

                {/* feature image container start */}
                <div className="order-1 lg:order-2 w-full lg:w-2/4 justify-end mb-4">
                    <img src="/images/solo_smart/E.png" className="mx-auto w-full md:w-3/5 lg:w-4/5" />
                </div>
                {/* feature image container end */}

                {/* feature image container start */}
                <div className="order-1 lg:order-2 w-full lg:w-2/4 justify-end mb-4">
                    <img src="/images/solo_smart/F.png" className="mx-auto w-full md:w-3/5 lg:w-4/5" />
                </div>
                {/* feature image container end */}
            </div>
            {/* feature container end */}


            {/* feature container start */}
            <div>
                <p className=" text-xs mb-6 feature-p-text font-bold italic leading-relaxed pr-20">
                    <sub className="align-top text-sm"></sub>“The present study showed that
                    large EOAs and EOAi’s remained substantially stable after the first
                    post-operative year. Our data are consistent with previous studies,
                    which demonstrated excellent early clinical and hemodynamic results”.<sup>1</sup>
                </p>
                {/* feature text container start */}
                <div className="w-full ">
                    <p className="text-xs 2xl:text-base mb-6 feature-p-text xl:w-4/5">
                    <sup>*</sup>Comparison made considering hemodynamic data on Solo Smart (Repossini et al., Eur J Cardiothorac Surg (2012) 41(5): 1104-1110) and data on native valves (Hanke et al.,
                    European Journal of Cardio-Thoracic Surgery (2013) 1–7).
                    </p>
                </div>
                {/* feature text container end */}


            </div>
            {/* feature container end */}


        </>
    )
}

export default ClinicalEvidence
